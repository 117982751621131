import React, { useEffect } from 'react';

const FormEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://answersai.activehosted.com/f/embed.php?id=9';
    script.charset = 'utf-8';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="_form_9"></div>;
};

export default FormEmbed;
