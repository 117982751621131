import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import explanation from "../images/explantion.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import TopBar from "../TopBar";

export const ShowShareChat = ({
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) => {
  const [showData, setShowData] = useState([]);
  const id = useParams();

  const getDataById = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/question-id`,
      {
        id: id.id,
      }
    );
    setShowData(response.data.data[0]);
  };
  
  useEffect(() => {
    getDataById();
  }, [ getDataById ]);

  const ParseAnswer = (answer) => {
    try {
      if (typeof answer !== "string" || answer.trim() === "") {
        return null;
      }
      const sanitizedAnswer = answer.replace(/[\u0000-\u001F]/g, "");
      const startIndex = sanitizedAnswer.indexOf("{");
      if (startIndex === -1 || startIndex === sanitizedAnswer.length - 1) {
        return null;
      }
      const jsonString = sanitizedAnswer.slice(startIndex);
      const answersObject = JSON.parse(jsonString);
      if (Array.isArray(answersObject.explanation)) {
        answersObject.explanation = answersObject.explanation.join("\n");
        answersObject.explanation = answersObject.explanation.replace(/"/g, "");
        let explain = answersObject.explanation;
        if (answersObject.explanation.length > 120) {
          explain += "...";
        }
        return explain;
      } else if (typeof answersObject.explanation === "string") {
        answersObject.explanation = answersObject.explanation.replace(/"/g, "");
        let explain = answersObject.explanation;
        return explain;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const optionChoice = (option) => {
    try {
      const jsonData = JSON.parse(option);

      if (jsonData.result && Array.isArray(jsonData.result)) {
        const choices = jsonData.result.map((item) => item.option_choice);
        const formattedChoices = choices.map((choice) => {
          if (typeof choice === "string" && /^\d+$/.test(choice)) {
            return choice.split("").join(", ");
          } else {
            return choice;
          }
        });
        return formattedChoices.join(", ");
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  return (
    <div>
      <TopBar
        handleShowLogin={handleShowLogin}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        logintype={logintype}
        setLoginType={setLoginType}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        emailData={emailData}
        setEmailData={setEmailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        setHeroLoader={setHeroLoader}
        setFlag={setFlag}
        flag={flag}
        loggedInId={loggedInId}
        setLoggedInId={setLoggedInId}
        isPaymentDone={isPaymentDone}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        showSub={showSub}
        setShowSub={setShowSub}
      />

      <div className="container-fluid main_Question_bank_">
        <div className="container">
          <div className="row">
            <div className="co-12 position-relative">
              <div className="Question_bank_heading__ text-center text-light">
                AnswersAi Intelligence Hub
              </div>
              <div className="question_bank_subheading__ text-center">
                Explore a vast archive of questions, sorted by subject.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid second_Question_bank_"
        id="showFullQuestion"
      >
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="col-12 d-flex justify-content-center">
                <Card
                  style={{ width: "90%", backgroundColor: "black" }}
                  className="mx-3 mt-3"
                >
                  <Card.Body className="second_question_explanation p-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="second_Question_bank_subject">
                        General
                      </div>
                      <div className="share_question_card position-relative"></div>
                    </div>
                    <div className="share_question_body">
                      {showData?.questions}
                    </div>
                    <div className="footer_div_share_question2">
                      <div className="share_question_exlanation p-4">
                        <div className="explanation_icon d-flex align-items-center pb-5 mt-4">
                          <img src={explanation} alt="" />
                          <span className="Explanation_text ms-2 text-light">
                            Explanation
                          </span>
                        </div>
                        <div>
                          <div className="p-3 pt-1">
                            {optionChoice(showData?.answers) !== null ? (
                              optionChoice(showData?.answers) !== "" ? (
                                <div className="explantion_button_ text-light">
                                  {optionChoice(showData?.answers)}
                                </div>
                              ) : (
                                <div className="other_div"></div>
                              )
                            ) : null}

                            <div className="share_question_subheading pt-2 text-light mt-5">
                              <div>{ParseAnswer(showData?.answers)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
