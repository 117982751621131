import React, { useEffect, useRef } from "react";
import Footer from "../Footer";
import TopBar from "../TopBar";
import Hero from "./Hero";
import Credential from "./Credential";
import Feature from "./Feature";
import Traction from "./Traction";
import Testimonial from "./Testimonial";
import About from "./About";
import Contact from "./Contact";
import ClipLoader from "react-spinners/ClipLoader";

export default function Home({
  heroLoader,
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
  scrollIntoView,
}) {
  const contactRef = useRef(null);

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    analiticalCall();
  }, []);

  useEffect(() => {
    if (heroLoader) {
      document.body.classList.add("body-loader");
    } else {
      document.body.classList.remove("body-loader");
    }
  }, [heroLoader]);

  const analiticalCall = () => {
    window.ttq.track("ViewContent");
  };
  return (
    <>
      {heroLoader ? (
        <div className="ring-loader">
          <ClipLoader size={250} color={"#fff"} loading={heroLoader} />
        </div>
      ) : (
        ""
      )}
      <TopBar
        handleShowLogin={handleShowLogin}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        logintype={logintype}
        setLoginType={setLoginType}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        emailData={emailData}
        setEmailData={setEmailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        setHeroLoader={setHeroLoader}
        setFlag={setFlag}
        flag={flag}
        loggedInId={loggedInId}
        setLoggedInId={setLoggedInId}
        isPaymentDone={isPaymentDone}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        showSub={showSub}
        setShowSub={setShowSub}
      />
      <>
        <Hero
          setHeroLoader={setHeroLoader}
          flag={flag}
          setFlag={setFlag}
          loggedInId={loggedInId}
          setLoggedInId={setLoggedInId}
          isPaymentDone={isPaymentDone}
          setIsPaymentDone={setIsPaymentDone}
          setPaymentPopup={setPaymentPopup}
          paymentPopup={paymentPopup}
          showSub={showSub}
          setShowSub={setShowSub}
          scrollToContact={scrollToContact}
        />
        <Credential />
        <Feature />
        <Testimonial />
        <Traction />
        <About />
        <Contact ref={contactRef} />
      </>
      <Footer />
    </>
  );
}
