import React, { useEffect, useState, useRef } from "react";
import HeroVideo from "../Video/HeroVideoJun4.mp4";
import LoginModal from "../LoginModal";
import axios from "axios";
import logo from "../images/answerai_icon.svg";
import Banner from "../Institution/Banner";

export default function Hero(props) {
  const [show, setShow] = useState(false);
  const [showDisplayFeatureModel, setDisplayFeatureModel] = useState(false);
  const [logintype, setLoginType] = useState(0);
  const [userQuestions, setUserQuestions] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [pricingPlansPopup, setPricingPlansPopup] = useState(false);
  const videoRef = useRef(null);

  const todayDate = new Date();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.75;
    }
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    props.setFlag(true);
    window.fbq("track", "CompleteRegistration");
  };

  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            props.setHeroLoader(false);
            props.setIsPaymentDone(true);
            props.setShowSub(true);
            localStorage.setItem("payment", true);
            props.setPaymentPopup(false);
            setShow(false);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                props.setHeroLoader(false);
                localStorage.setItem("payment", true);
                props.setIsPaymentDone(true);
                props.setShowSub(true);
                props.setShowInst(true);
              } else {
                localStorage.removeItem("payment");
              }
            }
          } else if (value.data.subscription == false) {
            props.setHeroLoader(false);
            props.setPaymentPopup(true);
            props.setIsPaymentDone(false);
            setDisplayFeatureModel(true);
            localStorage.removeItem("payment");
          }
        });
    } catch (err) {
      if (err.request) {
        if (err.request.status == 401) {
          props.setHeroLoader(false);
          props.setLoggedInId("");
          localStorage.removeItem("token");
          localStorage.removeItem("customer_id");
          localStorage.removeItem("payment");
          localStorage.removeItem("number");
        }
      }
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      localStorage.removeItem("token");
    }
  };

  return (
    <>
      <section className="hero">
      <Banner/>
        <div className="flex flex-col justify-center gap-8 items-center px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div className="md:order-last md:mr-10 rounded-lg inline-block mt-8 md:mt-0">
            <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[8px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px]">
              <div className="rounded-lg overflow-hidden h-[156px] md:h-[278px] bg-white dark:bg-gray-800">
                <video
                  ref={videoRef}
                  className="object-fill  w-full self-start  mx-auto"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src={HeroVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="relative mx-auto bg-gray-600 dark:bg-gray-700 rounded-b-xl rounded-t-sm h-[17px] max-w-[351px] md:h-[21px] md:max-w-[597px]">
              <div className="absolute left-1/2 top-0 -translate-x-1/2 rounded-b-xl w-[56px] h-[5px] md:w-[96px] md:h-[8px] bg-gray-800"></div>
            </div>
          </div>
          <div className="mt-4 md:mt-0 text-left md:ml-10 max-md:text-center">
            <h2
              className="mb-4 text-4xl font-extrabold text-white"
              style={{ lineHeight: "1.25" }}
            >
              <span style={{ color: "#6366F1" }}> One Click</span>{" "}
              <br className="hidden md:block" /> Question Solving
            </h2>
            <p className="mb-6 font-light text-white md:text-lg dark:text-gray-400">
              Accurate and Quick Explanations and Solutions on Web and Mobile.
            </p>
            {props.loggedInId ? (
              <div className="flex flex-col md:flex-row items-center gap-4">
                <button
                  className="block inline-flex items-center justify-center font-bold rounded-xl focus:ring-4 focus:ring-primary-300 rounded-lg focus:ring-4 focus:ring-primary-300 text-md px-3 py-4
                          dark:focus:ring-primary-900 transform hover:scale-110 transition-transform duration-300 w-3/4 max-md:w-full max-md:mx-auto "
                  style={{
                    color: "#6366F1",
                    background: "#fffff0",
                  }}
                  onClick={() =>
                    window.open("https://onelink.to/9h62gy", "_blank")
                  }
                >
                  Download on Mobile
                  <svg
                    className="ml-2 -mr-1 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                <button
                  className="block inline-flex items-center justify-center font-bold rounded-xl focus:ring-4 focus:ring-primary-300 rounded-lg focus:ring-4 focus:ring-primary-300 text-md px-3 py-4
                      dark:focus:ring-primary-900 transform hover:scale-110 transition-transform duration-300 w-3/4 max-md:w-full max-md:mx-auto "
                  style={{ background: "#6366F1" }}
                  onClick={() => handleShow()}
                >
                  Get started
                  <svg
                    className="ml-2 -mr-1 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
                <button
                  className="block inline-flex items-center justify-center font-bold rounded-xl focus:ring-4 focus:ring-primary-300 rounded-lg focus:ring-4 focus:ring-primary-300 text-md px-3 py-4
                      dark:focus:ring-primary-900 transform hover:scale-110 transition-transform duration-300 w-3/4 max-md:w-full max-md:mx-auto "
                  style={{
                    color: "#6366F1",
                    background: "#fffff0",
                  }}
                  onClick={() =>
                    window.open("https://onelink.to/9h62gy", "_blank")
                  }
                >
                  Download on Mobile
                  <svg
                    className="ml-2 -mr-1 w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            )}

            <div className="flex flex-col items-stretch mt-4 md:mt-5">
              <div className="flex items-stretch justify-start items-left gap-3 mt-2 max-md:mt-0">
                <img
                  loading="lazy"
                  src={logo}
                  className="aspect-square object-contain object-center w-3 overflow-hidden shrink-0 max-w-full"
                />
                <div className="text-white text-lg font-light self-center grow shrink basis-auto my-auto">
                  1.2 million+ <span className="font-bold">questions</span>{" "}
                  answered
                </div>
              </div>
              <div className="flex items-stretch justify-start items-left gap-3 mt-2 max-md:pb-8">
                <img
                  loading="lazy"
                  src={logo}
                  className="aspect-square object-contain object-center w-3 overflow-hidden shrink-0 max-w-full"
                />
                <div className="text-white text-lg font-light self-center grow shrink basis-auto my-auto">
                  300,000+ <span className="font-bold">students</span> on
                  platform
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LoginModal
        setHeroLoader={props.setHeroLoader}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        setFlag={props.setFlag}
        flag={props.flag}
        setEmailData={setEmailData}
        emailData={emailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        logintype={logintype}
        setLoginType={setLoginType}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        setLoggedInId={props.setLoggedInId}
        loggedInId={props.loggedInId}
        setIsPaymentDone={props.setIsPaymentDone}
        setPaymentPopup={props.setPaymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />
    </>
  );
}
