import React, { useState } from "react";
import LoginModal from "../LoginModal";
import axios from "axios";
import demoVideo from "../Video/BusinessHeroVideo.mp4";
import HeroBullet from "./components/HeroBullet";

export default function Hero(props) {
  const [show, setShow] = useState(false);
  const [showDisplayFeatureModel, setDisplayFeatureModel] = useState(false);

  const [logintype, setLoginType] = useState(0);
  const [userQuestions, setUserQuestions] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [pricingPlansPopup, setPricingPlansPopup] = useState(false);

  const todayDate = new Date();

  const handleClose = () => setShow(false);

  const bulletText = [
    {
      pre_bold_text: "Easy to ",
      bold_text: "integrate",
      post_bold_text: "and simple to use",
    },
    {
      pre_bold_text: "Answer student questions in ",
      bold_text: "seconds",
      post_bold_text: "",
    },
    {
      pre_bold_text: "",
      bold_text: "View",
      post_bold_text: "areas of improvement",
    },
    {
      pre_bold_text: "",
      bold_text: "Detect",
      post_bold_text: "AI plagiarism",
    },
  ]

  const checkLoggedInStatus = async () => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/check-payment-status`,
          {
            customer_id: localStorage.getItem("customer_id")
              ? localStorage.getItem("customer_id").toString()
              : "",
          },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then((value) => {
          var dateObj = new Date(value.data.current_period_end * 1000);
          var date =
            dateObj.getDate() +
            "/" +
            (dateObj.getMonth() + 1) +
            "/" +
            dateObj.getFullYear();
          if (value.data.subscription == "active") {
            props.setHeroLoader(false);
            props.setIsPaymentDone(true);
            props.setShowSub(true);
            localStorage.setItem("payment", true);
            props.setPaymentPopup(false);
            setShow(false);
          } else if (value.data.subscription == "canceled") {
            if (value.data.current_period_end) {
              if (dateObj > todayDate) {
                props.setHeroLoader(false);
                localStorage.setItem("payment", true);
                props.setIsPaymentDone(true);
                props.setShowSub(true);
                props.setShowInst(true);
              } else {
                localStorage.removeItem("payment");
              }
            }
          } else if (value.data.subscription == false) {
            props.setHeroLoader(false);
            props.setPaymentPopup(true);
            props.setIsPaymentDone(false);
            setDisplayFeatureModel(true);
            localStorage.removeItem("payment");
          }
        });
    } catch (err) {
      if (err.request) {
        if (err.request.status == 401) {
          props.setHeroLoader(false);
          props.setLoggedInId("");
          localStorage.removeItem("token");
          localStorage.removeItem("customer_id");
          localStorage.removeItem("payment");
          localStorage.removeItem("number");
        }
      }
    }
    var token = localStorage.getItem("token");
    if (localStorage.getItem("customer_id") === null) {
      localStorage.removeItem("token");
    }
  };

  return (
    <>
      <section className="institution-hero flex flex-col max-md:flex-col-reverse gap-[72px] max-md:gap-[4px] py-[60px] max-md:pt-[20px] max-md:py-[10px] items-center max-md:mx-auto ">
        <div className="flex flex-col gap-[48px] max-md:gap-[40px] items-center justify-center text-wrap max-w-full">
          <div className="flex flex-col gap-[12px] font-sans max-md:text-[36px] text-[48px] font-semibold items-center text-center text-black overflow-hidden">
            <div
              style={{
                background:
                  "linear-gradient(90deg, #999 1.42%, #FFF 68.46%, #999 100%)",
                "background-clip": "text",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "navy",
              }}
              className="text-wrap overflow-wrap max-md:pt-8"
            >
              Your Teachers are Amazing. Their
              <br className="max-md:block hidden" />
              <span
                className="ml-[10px] max-md:ml-0 border-l-[4px] border-[#6366F1] px-[10px]"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(99, 102, 241, 0.12) 0%, rgba(57, 59, 139, 0.12) 100%)",
                }}
              >
                AI Helpers
              </span>
              Should Be Too.
            </div>
            <div className="text-[24px] sm:text-[20px] md:text-[24px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px] max-md:text-[16px] text-black font-bold opacity-80 overflow-wrap">
  Supercharge your teachers with the most reliable and safe AI helpers.
  <br />
              <div className="flex items-stretch mt-4 md:mt-5 max-xl:items-center">
                <div className="flex max-xl:flex-col justify-center items-center gap-3 mt-2 max-xl:mx-auto">
                  {bulletText.map((item, index) => (
                    <HeroBullet key={index} {...item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => props.scrollToContact()}
            className="w-[240px] px-[24px] py-[16px] bg-[#000080] hover:bg-opacity-90 rounded-[40px] text-white font-sans font-semibold text-[16px]"
          >
            Get More Info
          </button>
        </div>
        <div className="w-[80%] max-md:w-full relative">
          <video
            autoPlay
            muted
            loop
            className="w-3/4 mx-auto rounded-[20px]  border-[#282828]"
          >
            <source src={demoVideo} type="video/mp4" />
          </video>
        </div>
      </section>

      <LoginModal
        setHeroLoader={props.setHeroLoader}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        setFlag={props.setFlag}
        flag={props.flag}
        setEmailData={setEmailData}
        emailData={emailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        logintype={logintype}
        setLoginType={setLoginType}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        setLoggedInId={props.setLoggedInId}
        loggedInId={props.loggedInId}
        setIsPaymentDone={props.setIsPaymentDone}
        setPaymentPopup={props.setPaymentPopup}
        checkLoggedInStatus={checkLoggedInStatus}
      />
    </>
  );
}
