import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import talkToBook from "../Video/Talk_to_book.mp4";
import lectureMode from "../Video/LectureMode.mp4";
import essayMode from "../Video/essay-mode.mp4";
import photoSolve from "../Video/Photo_solve.mp4";
import autoSolve from "../Video/hero-video.mp4";

export default function FeatureCards() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        cssEase: "linear",
        lazyLoad: true,
    };

    return (
        <>
            <style>
                {`
                .slick-dots li button:before {
                    color: white;
                    font-size: 18px; /* Adjust the size as needed */
                }
                .slick-dots li.slick-active button:before {
                    color: white;
                    font-size: 18px; /* Adjust the size as needed */
                }
                .slick-prev:before, .slick-next:before {
                    font-size: 24px !important; /* Adjust the size as needed */
                    color: white !important; /* Adjust the color as needed */
                }
                .slick-prev, .slick-next {
                    top: 60%;
                    z-index: 10;
                }
                .slick-prev {
                    left: 10px !important; /* Closer to the center on mobile */
                }
                .slick-next {
                    right: 10px !important; /* Closer to the center on mobile */
                }
                @media (min-width: 640px) { /* Adjust for larger screens, 640px is Tailwind's default for 'sm' */
                .slick-prev:before, .slick-next:before {
                    font-size: 48px !important; /* Adjust the size as needed */
                } 
                .slick-prev, .slick-next {
                    top: 50% !important; /* Center vertically */
                    transform: translateY(-50%) !important; /* Ensure it's centered vertically */
                }    
                .slick-prev {
                        left: -60px !important; /* Original positioning for larger screens */
                    }
                    .slick-next {
                        right: -40px !important; /* Original positioning for larger screens */
                    }
                }
            `}
            </style>

            <section className="flex flex-col justify-center hero w-full">
                <div class="max-w-screen-md flex flex-wrap items-center justify-between mx-auto px-4 pt-20 max-md:pt-8">
                    <h2 className="text-center text-white text-4xl font-bold max-sm:mt-6 my-10 mx-auto max-md:leading-[45px] max-md:text-3xl" style={{ lineHeight: '1.25' }}>
                        Boost your
                        <span style={{ color: '#6366F1', px: '2px' }}> GPA </span> with our Study Tools
                    </h2>
                </div>
                <div className="w-1/2 sm:mb-10 mx-auto max-md:w-full max-md:pb-16 max-md:p-4">
                    <Slider {...settings}>
                        <div>
                            <video className="w-full rounded-xl" autoPlay loop muted playsInline>
                                <source src={lectureMode} type="video/mp4" />
                            </video>
                            <h2 className="text-center text-white text-3xl font-bold mt-10 max-md:text-2xl" style={{ lineHeight: '1.25' }}>
                                Lecture Mode
                            </h2>
                            <p className="text-center text-white text-md p-4 max-md:text-md">Detailed lecture notes in seconds! Interact with your lecture and learn like never before.</p>
                        </div>
                        <div className="mb-4">
                            <video className="w-full rounded-xl" autoPlay loop muted playsInline>
                                <source src={talkToBook} type="video/mp4" />
                            </video>

                            <h2 className="text-center text-3xl font-bold mt-10 text-white max-md:text-2xl" style={{ lineHeight: '1.25' }}>
                                Talk to Book
                            </h2>
                            <p className="text-center text-white text-md p-4 max-md:text-md">Upload any pdf - lecture slides or digital textbooks - to ask questions or seek in-depth explanations directly from your uploaded content.</p>
                        </div>
                        <div>
                            <video className="w-full rounded-xl" autoPlay loop muted playsInline>
                                <source src={essayMode} type="video/mp4" />
                            </video>

                            <h2 className="text-center text-white text-3xl font-bold mt-10 max-md:text-2xl" style={{ lineHeight: '1.25' }}>
                                Essay Mode
                            </h2>
                            <p className="text-center text-white text-md p-4 max-md:text-md">Type in the prompt or upload PDF instructions, and let our Al craft your essay.</p>
                        </div>
                        <div>
                            <video className="w-full rounded-xl" autoPlay loop muted playsInline>
                                <source src={photoSolve} type="video/mp4" />
                            </video>
                            <h2 className="text-center text-white text-3xl font-bold mt-10 max-md:text-2xl" style={{ lineHeight: '1.25' }}>
                                Photo Solve
                            </h2>
                            <p className="text-center text-white text-md p-4 max-md:text-md">Stuck on a written assignment? Take a picture using the AnswersAi app, and get AI generated explanations in seconds! </p>
                        </div>
                        <div>
                            <video className="w-full rounded-xl" autoPlay loop muted playsInline>
                                <source src={autoSolve} type="video/mp4" />
                            </video>
                            <h2 className="text-center text-white text-3xl font-bold mt-10 max-md:text-2xl" style={{ lineHeight: '1.25' }}>
                                Auto Solve
                            </h2>
                            <p className="text-center text-white text-md p-4 max-md:text-md">One-click solutions and explanations on Blackboard and Canvas.</p>
                        </div>

                    </Slider>
                </div>
            </section>
        </>
    );
}