import React from "react";
import logo from "../images/logo.png";
import instagram from "../images/Instagram.svg";
import tiktok from "../images/tiktok.svg";
import facebook from "../images/Facebook.svg";

export default function FlowbiteFooter() {
    return (
  <footer className="flex flex-col bg-footerbg bg-cover p-16 md:p-24 md:pb-4 pb-4 font-redhat">
                <div className="flex flex-col md:flex-row justify-between pb-8 footer-content">
                    <div className="footer-items">
                        <a href="https://answersai.com/" className="flex justify-center md:justify-start items-center text-4xl text-white font-bold mb-4 p-0 font-redhat no-underline">
                            <img src={logo} className='max-h-9 pr-3' alt=""></img>Answers<span style={{ color: '#6366F1' }}>Ai</span>
                        </a>
                        <div className="grid grid-cols-1 sm:grid-cols-[auto_auto_auto] text-center md:text-left gap-16 pt-2">
                            <ul className="m-0 p-0 pt-3 list-none space-y-2 sm:w-fit">
                                <h2 className="text-xl font-semibold mb-4 text-white">Support</h2>
                                <li><a className="text-gray-400 no-underline hover:text-white transition-all" href="https://answersai.com/faq" target="_blank" rel="noreferrer">Contact Us</a></li>
                                <li><a className="text-gray-400 no-underline hover:text-white transition-all" href="https://answersai.com/faq" target="_blank" rel="noreferrer">FAQ</a></li>
                            </ul>
                            <ul className="m-0 p-0 pt-3 list-none space-y-2">
                                <h2 className="text-xl font-semibold mb-4 text-white">Solutions</h2>
                                <li><a className="text-gray-400 no-underline hover:text-white transition-all no-underline" href="https://answersai.com" target="_blank" rel="noreferrer">For Students</a></li>
                                <li><a className="text-gray-400 no-underline hover:text-white transition-all" href="https://answersai.com/institutions" target="_blank" rel="noreferrer">For Institutions</a></li>
                            </ul>
                            <ul className="m-0 p-0 pt-3 list-none space-y-2">
                                <h2 className="text-xl font-semibold mb-4 text-white">Legal</h2>
                                <li><a className="text-gray-400 no-underline hover:text-white transition-all" href="https://answersai.com/privacy-policy" target="_blank" rel="noreferrer">Privacy</a></li>
                                <li><a className="text-gray-400 no-underline hover:text-white transition-all" href="https://answersai.com/copyright-policy" target="_blank" rel="noreferrer">Copyright</a></li>
                                <li><a className="text-gray-400 no-underline hover:text-white transition-all" href="https://answersai.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Service</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex grow pt-8 md:pt-0 justify-center md:justify-end max-y-16 items-start space-x-4">
                        <a href="https://www.facebook.com/profile.php?id=61555915947620" target="_blank" rel="noreferrer"><img className="hover:opacity-60 transition-all" src={facebook} alt="Facebook"/>
                        </a>
                        <a href="https://www.tiktok.com/@answersai.com" target="_blank" rel="noreferrer"><img className="hover:opacity-60 transition-all" src={tiktok} alt="Tiktok"/>
                        </a>
                        <a href="https://www.instagram.com/answersai/" target="_blank" rel="noreferrer"><img className="hover:opacity-60 transition-all" src={instagram} alt="Instagram"/>
                        </a>
                    </div>
                </div>
                <div className="flex flex-row md:justify-center text-justify md:text-center p-6 px-0 md:p-16 md:px-0 font-regular text-sm text-white text-opacity-60 footer-cc">
                © 2024 AnswersAi Corp. All Rights Reserved. AnswersAi, Corp. does not condone plagiarism or academic dishonesty. If our services have been misused, we reserve the right to take actions to maintain the integrity of our platform, including but not limited to co-operating with academic institutions and suspending accounts. 
            </div>
        </footer>
    /*<footer className="bg-black">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between margin-x">
          <div className="mb-6 md:mb-0">
            <a href="https://answersai.com/" className="flex items-center">
              <img src={logo} className="h-8 me-3" alt="AnswersAi Logo" />
              <div className="justify-center text-indigo-500 text-3xl font-bold tracking-tighter self-stretch">
                <span className="text-white pr-0.5">Answers</span>Ai
                <sup className="text-white font-normal">&trade;</sup>
              </div>
            </a>
            <div className="flex mt-4 space-x-5">
              <a
                href="https://www.tiktok.com/@answersai.com"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <img src={tiktok} className=" h-12" alt="TikTok page" />
                <span className="sr-only">TikTok page</span>
              </a>
              <a
                href="https://www.instagram.com/answersai/"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <img src={instagram} className="h-12" alt="Instagram page" />
                <span className="sr-only">Instagram page</span>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61555915947620"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <img src={facebook} className="h-10" alt="Facebook page" />
                <span className="sr-only">Facebook page</span>
              </a>
            </div>
            <div className="w-1/2 text-left text-white text-xs font-light tracking-tight mt-5 max-md:w-full">
              AnswersAi, Corp. does not condone plagiarism or academic
              dishonesty. If our services have been misused, we reserve the
              right to take actions to maintain the integrity of our platform,
              including but not limited to co-operating with academic
              institutions and suspending accounts.
            </div>
          </div>
          <div class="flex justify-between space-x-5 md:gap-10">
            <div class="w-1/3">
              <h2 class="mb-6 text-sm font-semibold text-white uppercase">
                More
              </h2>
              <ul class="text-gray-400 font-medium font-responsive">
                <li class="mb-4">
                  <a href="/institutions" class="hover:underline">
                    For Institutions
                  </a>
                </li>
                <li class="mb-4">
                  <a href="/" class="hover:underline">
                    Home
                  </a>
                </li>
                {/* Temporarily removed DO NOT DELETE */
                /* <li class="mb-4">
                  <a href="https://answersai.notion.site/" target="_blank" rel="noopener noreferrer">Careers</a>                
                </li> 
              </ul>
            </div>
            <div class="w-1/3">
              <h2 class="mb-6 text-sm font-semibold text-white uppercase">
                Students
              </h2>
              <ul class="text-gray-400 font-medium font-responsive">
                <li class="mb-4">
                  <a href="/faq" class="hover:underline">
                    FAQ
                  </a>
                </li>
                <li>
                  <a href="/faq" class="hover:underline">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-1/3">
              <h2 class="mb-6 text-sm font-semibold text-white uppercase">
                Legal
              </h2>
              <ul class="text-gray-400 font-medium font-responsive">
                <li class="mb-4">
                  <a href="/privacy-policy" class="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li class="mb-4">
                  <a href="/terms-of-use" class="hover:underline">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="/copyright-policy" class="hover:underline">
                    Copyright Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8 mx-10" />
        <div className="sm:flex sm:items-center sm:justify-between mx-10">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024{" "}
            <a href="https://answersai.com/" className="hover:underline">
              AnswersAi, Corp.
            </a>{" "}
            All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>*/
  );
}
